<template>
  <div class="container mt-3">
    <div class="card">
      <div class="card-body">
        <div class="row text-start" v-if="budget">
          <div class="col-12">
            <img src="@/assets/img/logos/color.png" class="logo-budget mb-3" />
            <h6 class="fw-bold mt-2">ORÇAMENTO</h6>
            <p class="mb-4">
              DATA: {{ dateTimeSqlToDateTimeBR(budget.createdAt) }}
            </p>
          </div>
          <div class="col-12">
            <p>Nome: {{ budget.leadName }}</p>
          </div>
          <div class="col-12">
            <p>WhatsApp: {{ budget.leadPhone }}</p>
          </div>
          <div class="col-12">
            <p>Cidade: {{ budget.cityName }}</p>
          </div>
          <div class="col-12">
            <p>Finalidade: {{ budget.purpose }}</p>
          </div>
          <div class="col-12">
            <p>
              Produto: FCK {{ budget.productFck }} | Brita
              {{ budget.productGravel }} | Slump >= {{ budget.productSlump }} mm
            </p>
          </div>
          <div class="col-12">
            <p>Volume: {{ budget.volume }}m³</p>
          </div>
          <div class="col-12">
            <p>
              Total do Orçamento:
              {{ moneyToCurrencyBr(budget.priceTotal) }}
            </p>
          </div>
          <h6 class="text-primary">
            Esse é um orçamento automático e preliminar! Para negociar, entender
            os valores e sanar qualquer dúvida, fale com nossos vendedores.
          </h6>
          <div class="text-center">
            <router-link
              :to="`/`"
              class="btn btn-primary btn-labeled btn-labeled-start rounded-pill"
              type="button"
            >
              <span
                class="btn-labeled-icon bg-black bg-opacity-20 rounded-pill"
              >
                <i class="ph ph-arrow-clockwise"></i>
              </span>
              Nova cotação
            </router-link>
          </div>
        </div>
        <div v-else>
          <h6 class="mb-0">Orçamento não encontrado!</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.logo-budget {
  width: 150px;
}
</style>
<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { moneyToCurrencyBr } from '@/utils/strings'

export default {
  name: 'ShowBudget',
  props: {
    budget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
      moneyToCurrencyBr,
      defaultColspan: 3,
    }
  },
  methods: {},
  mounted() {},
}
</script>
