<template>
  <div
    ref="contenthome"
    class="d-flex h-100 justify-content-center"
    :class="showMode"
  >
    <div ref="contentapp" class="flex-fill text-center pb-5">
      <LoadingData v-if="isLoadingBudget" />
      <div v-else>
        <ShowBudget :budget="budget" @setHeightContent="setHeightContent" />
      </div>
    </div>
  </div>
</template>

<script>
import ShowBudget from '@/components/budget/ShowBudget.vue'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'BudgetDetails',
  data() {
    return {
      isLoadingBudget: true,
      budget: null,
      uuid: this.$route.params.uuid,
      windowHeight: window.innerHeight,
      contentApp: 0,
      contentHome: 0,
      showMode: 'align-items-center',
    }
  },
  methods: {
    setHeightContent() {
      this.contentApp = this.$refs.contentapp.clientHeight
      this.contentHome = this.$refs.contenthome.clientHeight
      if (this.contentApp > this.contentHome) {
        this.showMode = 'align-items-start'
      } else {
        this.showMode = 'align-items-center'
      }
    },
    async getBudget() {
      try {
        this.isLoadingBudget = true
        this.budget = await this.$store.dispatch('getBudget', this.uuid)
        this.isLoadingBudget = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  components: {
    LoadingData,
    ShowBudget,
  },

  computed: {},
  mounted() {
    this.getBudget()
  },
}
</script>
